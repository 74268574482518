import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
// Icons & imgeas common files
import * as Img from '../../components/Img';

const Contactusform = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
     <div className='contactus-form p-70'>
            <img src={Img.Footergradiant}  className='footer-gradiant'/>
         <div className='container-lg'>
             <div className='row row-gap-4'>
                <div className='col-md-6'>
                    <div className='cf-left' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500">
                        <div className='common-heading'>
                        Rated 5/5 stars In over 1,000+ reviews 
                        </div>
                        <div className='common-subheading'>
                            Thousands of marketers, writers, and entrepreneurs trust and love ChatGenius 
                        </div>


                        <div className='cf-form mt-4 common-input'>
                            <div className='from-group'>
                                <input className='form-control' type="text" placeholder="Name" value="" />
                            </div>
                            <div className='from-group'>
                                <input className='form-control' type="text" placeholder="Email" value="" />
                            </div>
                            <div className='from-group'>
                                <input className='form-control' type="text" placeholder="Phone" value="" />
                            </div>
                            <div class="me-0 ms-auto"><button class="common-btn-item cbi-solid text-center">send </button></div>
                        </div>   

                    </div>
                </div>
                <div className='offset-md-1 col-md-5'>
                <div className='cf-right' data-aos="fade-down" data-aos-duration="2000" data-aos-delay="1500">
                    <img src={Img.Contactus} />
                </div>
                </div>
             </div>
         </div>
     </div>
  )
}

export default Contactusform