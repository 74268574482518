import React from 'react'
import Slider from 'react-infinite-logo-slider'

// Icons & imgeas common files
import * as Img from '../../components/Img';

const Trustedpartner = () => {
    return (
        <div className='trusted-Partner p-70'>
            <div className='common-heading ch-medium text-center pb-5'>
                Trusted Partner
            </div>
            <div className='tp-slider'>
                <Slider
                    width="240px"
                    duration={40}
                    pauseOnHover={false}
                    blurBorders={false}
                    blurBoderColor={'#fff'}
                    
                >
                    <Slider.Slide>
                        <img src={Img.Hsbc} />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={Img.Medtech}  />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={Img.Miro} />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={Img.Pfizer}  />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={Img.Toyota} />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={Img.Trivago} />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={Img.Pfizer} />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={Img.Stripe} />
                    </Slider.Slide>

                </Slider>
            </div>
        </div>
    )
}

export default Trustedpartner