import React from 'react'
import { Link } from 'react-router-dom';
import '../../css/footer.css'


const Footer = () => {
    return (
        <div className='footer-setion'>
            <div className='containe-lg'>
                <div className='fs-inner'>
                    <div className='fs-left'>
                        <div className='common-subheading'>
                            © 2024 ™. All Rights Reserved.
                        </div>
                    </div>
                    <div className='fs-right'>
                        <ul>
                            <li><Link>About Us</Link></li>
                            <li><Link>License</Link></li>
                            <li><Link>Contribute</Link></li>
                            <li><Link>Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer