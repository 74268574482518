import React from 'react'
import Introducingai from './Introducingai';
import Trustedpartner from './Trustedpartner';
import Startupidea from './Startupidea';
import Letstalkstart from './Letstalkstart';
import Ratedreview from './Ratedreview';
import Contactusform from './Contactusform';

const Home = () => {
    return (
        <>   
        <Introducingai />
        <Trustedpartner />
        <Startupidea />
        <Letstalkstart />
        <Ratedreview />
        <Contactusform />

        </>
    )
}

export default Home