import React,{useEffect} from "react";
// Icons & imgeas common files
import * as Img from '../../components/Img';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Letstalkstart() {
    useEffect(() => {
        AOS.init();
      }, [])
    return (
        <div className='lets-talk-tech p-70'>
            <img src={Img.Bodygradiantbg} className='body-gradiant' />
            <div className='container-lg'>
                <div className='ltt-inner mb-4'>
                    <div className='common-heading text-center'>
                        Lets Talk Tech
                    </div>
                    <div className='common-subheading text-center'>
                        Lorem ipsum dolor sit amet consectetur. Nullam elit vivamus mattis libero egestas laoreet aliquam tincidunt. Feugiat sed libero facilisi semper nec proin. Dui nunc id venenatis non in dignissim bibendum. Hendrerit amet id facilisis vestibulum iaculis.
                    </div>
                    <div className='text-center mx-auto'>
                        <button class="common-btn-item cbi-solid text-center">Get Started</button>
                    </div>
                </div>

                <div className='ltt-inner-detail' data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="1000">
                     <img src={Img.Letstalk} />
                </div>
            </div> 
        </div>
    )
}

export default Letstalkstart