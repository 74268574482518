import React from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';

// Icons & imgeas common files
import Icons from '../../components/Icon';

const Ratedreview = () => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [

      {
        breakpoint: 1500, // At screens less than or equal to 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300, // At screens less than or equal to 1024px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024, // At screens less than or equal to 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768, // At screens less than or equal to 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600, // At screens less than or equal to 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],

  };

  return (
    <div className='rated-reviews p-70'>
      <div className='container-lg'>
        <div className='rr-inner pb-5'>
          <div className='common-heading text-center'>
            Rated 5/5 stars In over 1,000+ reviews
          </div>
          <div className='common-subheading text-center'>
            Thousands of marketers, writers, and entrepreneurs trust and love ChatGenius
          </div>
        </div>
      </div>

      <div className='rr-slider'>
        <Slider {...settings}>
          <div>
            <div className='common-box'>
              <div className='star-reacting'>
                <ul>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarSvg /></Link></li>
                </ul>

              </div>
              <div className='common-heading ch-small'>
                It's made my job so much easier
              </div>
              <div className='common-subheading '>
                This tool has been a game-changer for me. From managing my tasks to collaborating with my team, it's made everything so much easier. Highly recommended!
              </div>
              <div className='review-detail'>
                <div className='rd-inne-left'>
                  <Icons.UserSvg />
                </div>
                <div className='rd-inne-right'>
                  <div className='common-heading ch-small'>Emma Roberts</div>
                  <div className='common-text'>14 February 2023</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <   div className='common-box'>
              <div className='star-reacting'>
                <ul>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarSvg /></Link></li>
                </ul>

              </div>
              <div className='common-heading ch-small'>
                It's made my job so much easier
              </div>
              <div className='common-subheading '>
                This tool has been a game-changer for me. From managing my tasks to collaborating with my team, it's made everything so much easier. Highly recommended!
              </div>
              <div className='review-detail'>
                <div className='rd-inne-left'>
                  <Icons.UserSvg />
                </div>
                <div className='rd-inne-right'>
                  <div className='common-heading ch-small'>Emma Roberts</div>
                  <div className='common-text'>14 February 2023</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='common-box'>
              <div className='star-reacting'>
                <ul>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarSvg /></Link></li>
                </ul>

              </div>
              <div className='common-heading ch-small'>
                It's made my job so much easier
              </div>
              <div className='common-subheading '>
                This tool has been a game-changer for me. From managing my tasks to collaborating with my team, it's made everything so much easier. Highly recommended!
              </div>
              <div className='review-detail'>
                <div className='rd-inne-left'>
                  <Icons.UserSvg />
                </div>
                <div className='rd-inne-right'>
                  <div className='common-heading ch-small'>Emma Roberts</div>
                  <div className='common-text'>14 February 2023</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='common-box'>
              <div className='star-reacting'>
                <ul>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarSvg /></Link></li>
                </ul>

              </div>
              <div className='common-heading ch-small'>
                It's made my job so much easier
              </div>
              <div className='common-subheading '>
                This tool has been a game-changer for me. From managing my tasks to collaborating with my team, it's made everything so much easier. Highly recommended!
              </div>
              <div className='review-detail'>
                <div className='rd-inne-left'>
                  <Icons.UserSvg />
                </div>
                <div className='rd-inne-right'>
                  <div className='common-heading ch-small'>Emma Roberts</div>
                  <div className='common-text'>14 February 2023</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='common-box'>
              <div className='star-reacting'>
                <ul>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarSvg /></Link></li>
                </ul>

              </div>
              <div className='common-heading ch-small'>
                It's made my job so much easier
              </div>
              <div className='common-subheading '>
                This tool has been a game-changer for me. From managing my tasks to collaborating with my team, it's made everything so much easier. Highly recommended!
              </div>
              <div className='review-detail'>
                <div className='rd-inne-left'>
                  <Icons.UserSvg />
                </div>
                <div className='rd-inne-right'>
                  <div className='common-heading ch-small'>Emma Roberts</div>
                  <div className='common-text'>14 February 2023</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='common-box'>
              <div className='star-reacting'>
                <ul>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarfillSvg /></Link></li>
                  <li><Link><Icons.StarSvg /></Link></li>
                </ul>

              </div>
              <div className='common-heading ch-small'>
                It's made my job so much easier
              </div>
              <div className='common-subheading '>
                This tool has been a game-changer for me. From managing my tasks to collaborating with my team, it's made everything so much easier. Highly recommended!
              </div>
              <div className='review-detail'>
                <div className='rd-inne-left'>
                  <Icons.UserSvg />
                </div>
                <div className='rd-inne-right'>
                  <div className='common-heading ch-small'>Emma Roberts</div>
                  <div className='common-text'>14 February 2023</div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default Ratedreview