import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


import '../../css/header.css';


// Icons & imgeas common files
import * as Img from '../../components/Img';


const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  return (
    <>
      <header className={`main-header ${isScrolled ? 'scroll' : ''}`}>
        <div className="mh-container container-lg">
          <div className="mh-logo">
            <img src={Img.logo} alt="Logo" />
          </div>

          <nav className={`mh-nav ${isMobileMenuOpen ? 'active' : ''}`}>
            <ul className="mh-menu">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/">Learn More</Link></li>
              <li><Link to="/">About Us</Link></li>
              <li><Link to="/">License</Link></li>
              <li><Link to="/">Contribute</Link></li>
              <li><Link to="/">Contact Us</Link></li>
            </ul>
          </nav>

          <div className="mh-buttons common-btn-group">
            <button className="common-btn-item cbi-light">Try Free Demo</button>
            <button className="common-btn-item cbi-solid">Get Started</button>
          </div>

          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </header>

      {isMobileMenuOpen && <div className="overlay" onClick={toggleMobileMenu}></div>}
    </>
  );
};

export default Header;
