import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Icons & imgeas common files
import * as Img from '../../components/Img';

const contentArray = [
    {
        imgSrc: Img.Genai,
        heading: 'GenAI Made Simple',
        text: 'Lorem ipsum dolor sit amet consectetur. Nullam elit vivamus mattis libero egestas laoreet'

    },
    {
        imgSrc: Img.Genai,
        heading: 'GenAI Made Simple',
        text: 'Lorem ipsum dolor sit amet consectetur. Nullam elit vivamus mattis libero egestas laoreet'
    },
    {
        imgSrc: Img.Genai,
        heading: 'GenAI Made Simple',
        text: 'Lorem ipsum dolor sit amet consectetur. Nullam elit vivamus mattis libero egestas laoreet'
    },
    {
        imgSrc: Img.Genai,
        heading: 'GenAI Made Simple',
        text: 'Lorem ipsum dolor sit amet consectetur. Nullam elit vivamus mattis libero egestas laoreet'
    }
];





const Startupidea = () => {

    useEffect(() => {
        AOS.init();
      }, [])

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Initialize based on current width

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize); // Cleanup
        };
    }, []);

    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
          {
            breakpoint: 768, // At screens less than or equal to 768px
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600, // At screens less than or equal to 480px
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
        ],
    
      };

    return (
        <div className='idea-into-startup'>
            <div className='container-lg'>
                <div className='row row-gap-4'>
                    <div className='col-12 col-sm-12 col-md-4'>
                        <div className='its-left'>
                            <div className='common-heading'>
                                Turn your idea into a startup
                            </div>
                            <div className='common-subheading'>
                                We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-8'>
                        <div className='its-right'>
                            <div className='col-12'>
                                {isMobile ? (
                                    <Slider {...settings}>
                                        {contentArray.map((item, index) => (
                                            <div className='col-12 px-3' key={index}>
                                                <div className='common-box' >
                                                    <img src={item.imgSrc} alt={item.heading} />
                                                    <div className='common-heading ch-small'>{item.heading}</div>
                                                    <div className='common-text'>{item.text}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                ) : (
                                    <div className='row row-gap-3'>
                                        {contentArray.map((item, index) => (
                                            <div className='col-12 col-sm-6 col-md-6 px-3' key={index}>
                                                <div className='common-box' data-aos="flip-left"  data-aos-duration="1500" data-aos-delay="500">
                                                    <img src={item.imgSrc} alt={item.heading} />
                                                    <div className='common-heading ch-small'>{item.heading}</div>
                                                    <div className='common-text'>{item.text}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Startupidea