import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../containers/Header/Header";

import Footer from "../containers/Footer/Footer";

// Pages

import Home from "../containers/PageHome/Home";

 export const pages = [

 { path: "/", component: Home },

 ];

const MyRoutes = () => {

  return (
    <>
        <ScrollToTop />
        <Loader />
        <Header />
          <Routes>
            {pages.map(({ component: Component, path }, index) => {
              return <Route key={index} element={<Component />} path={path} />;
            })}
          </Routes>
        <Footer />
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
        <MyRoutes />
    </Router>
  );
}

export default MainRoutes;