import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Icons & imgeas common files
import * as Img from '../../components/Img';


function Introducingai() {
    useEffect(() => {
        AOS.init();
      }, [])
    return (

        <div className='introducing-custmized-ai'>
            <img src={Img.Gradiantbg} className="ica-bg" />
            <div className='container-lg'>
                <div className='row row-gap-4'>
                    <div className='col-md-6'>
                        <div className='ica-left' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500">
                            <div className='common-heading'>
                                Introducing Our New Customized GenAl ChatBot Service on AWS
                            </div>
                            <div className='common-subheading'>
                                Lorem ipsum dolor sit amet consectetur. Nullam elit vivamus mattis libero egestas laoreet aliquam tincidunt. Feugiat sed libero facilisi semper nec proin. Dui nunc id venenatis non in dignissim bibendum. Hendrerit amet id facilisis vestibulum iaculis.
                            </div>

                            <div className="mh-buttons common-btn-group">
                                <button className="common-btn-item cbi-solid">Get Started</button>
                                <button className="common-btn-item cbi-light">Try Free Demo</button>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6'>
                        <div className='ica-right' data-aos="fade-down" data-aos-duration="2000" data-aos-delay="1500">
                            <img src={Img.Aibanner} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introducingai